import defaults from 'lodash.defaults';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { Style } from 'ol/style';
import get from 'lodash.get';
import { IStyleFunction } from './styleTypes';

export interface IThumbnailOptions {
    /**
     * default is `url`
     */
    thumbnailField?: string;
    /**
     *  label field to display if thumbnail is missing from the feature
     */
    labelField?: string;
    /**
     * ID field property to use as unique cache key
     */
    idField?: string;
    /**
     * default is 10
     */
    thumbnailSize?: number;

}

export interface ThumbnailStyleFunction extends IStyleFunction {
    options: IThumbnailOptions;
}

export function generateInitials(text: string): string {
    if (!text) {
        return '?';
    }
    if(text.length <= 3){
        return text;
    }

    return text?.split(' ').map((word) => word[0]?.toUpperCase()).join('');
}

export function createThumbnailStyle(opt: IThumbnailOptions): ThumbnailStyleFunction {
    defaults(opt, {
        thumbnailField: 'url',
        thumbnailSize: 30,
        idField: 'id',
    } as IThumbnailOptions);

    const styleCache: Record<string, Style> = {};

    const result: ThumbnailStyleFunction  = function (feature: Feature<Point>) {
        const props = feature.getProperties();
        const photoUrl = get(props, opt.thumbnailField);
        const id = get(props, opt.idField);
        const backupText = generateInitials( get(props, opt.labelField));

        if (!styleCache[id]) {
            styleCache[id] = new Style({
                renderer: function (pixelCoordinates: number[], state) {
                    const radius = opt.thumbnailSize / 2;
                    const [x, y] = pixelCoordinates;
                    const [xOffset, yOffset] = [x, y].map((coord) => (coord - radius));

                    const context = state.context;

                    // load the image
                    if (photoUrl && !feature.get('image')) {
                        const img = document.createElement('img');
                        img.onload = function () {
                            feature.set('imageLoaded', true);
                        };
                        img.src = photoUrl;
                        feature.set('image', img);
                    }

                    // if the image isn't yet loaded
                    // render the back up text instead
                    if (!feature.get('imageLoaded')) {
                        context.beginPath();
                        context.arc(x, y, radius, 0, 2 * Math.PI, false);
                        context.fillStyle = '#FAAF3E';
                        context.fill();
                        context.lineWidth = 3;
                        context.strokeStyle = '#fff';
                        context.stroke();

                        const fontSize = backupText.length <= 2 ?
                            radius :
                            radius / backupText.length * 2.5;

                        context.font = `${fontSize}px sans-serif`;
                        context.strokeStyle = '#fff';
                        context.lineWidth = 0;
                        context.fillStyle = '#fff';
                        context.textAlign = 'center';
                        context.textBaseline = 'middle';
                        context.fillText(backupText, x, y);

                        return;
                    }

                    // clip image to be rounded
                    context.save();
                    context.beginPath();
                    context.arc(x, y, radius, 0, 2 * Math.PI, true);
                    context.clip();

                    context.drawImage(feature.get('image'), xOffset, yOffset, opt.thumbnailSize, opt.thumbnailSize);
                    context.restore();
                },

            });
        }

        return styleCache[id];
    };

    result.dfpStyleType = 'thumbnail';
    result.options = opt;
    return result;
}
