import { toLonLat } from 'ol/proj';
import {Extent} from 'ol/extent';
import qs from 'qs';
import VectorLayer from 'ol/layer/Vector';
import { LogManager } from 'aurelia-framework';

const log = LogManager.getLogger('dfp:map');

export interface IDfpLayer {
    baseUrl: string;
    programId: number;
    typeId: number;

}

export interface IUrlParams {
    baseUrl: string;
    programId: number;
    serviceType: 'programs-assets' | 'programs-users-roles' | 'form-submissions' | 'projects' | 'projects-files';
    typeId: number;
    query?: Record<string, any>
}

export interface DFPUrlObj {
    (extent: Extent): string;
    setParams: (params: Partial<IUrlParams>, layer: VectorLayer<any>) => void,
    params: IUrlParams;
    _cacheKey: string;
}

export function getDfpGeojsonUrlObject(params: IUrlParams): DFPUrlObj {

    function dfpGeoJSONUrl(extent){
        const first = toLonLat([extent[0], extent[1]]);
        const second = toLonLat([extent[2], extent[3]]);
        const query = {};
        Object.entries(params.query || {}).forEach(([key, val]) => {
            if(typeof val !== 'undefined'){
                query[key] = val;
            }
        });
        const queryParams = qs.stringify(query);
        return `${params.baseUrl}/ogc/geojson/${params.programId}/${params.serviceType}/${params.typeId}?bbox=${first.join(',')},${second.join(',')}&${queryParams}`;
    }

    dfpGeoJSONUrl._cacheKey = createLayerCacheKey(params.query);
    dfpGeoJSONUrl.setParams = function setParams(p: IUrlParams, layer: VectorLayer<any>): void{
        Object.assign(params, {
            ...p,
            query: (p.query ? {
                $select: params.query?.$select,
                $modify: params.query?.$modify,
                ...p.query,
            } : params.query),
        });

        const newKey = createLayerCacheKey(params.query);
        if(dfpGeoJSONUrl._cacheKey !== newKey){
            log.debug(`[dfpGeoJSONUrl._cacheKey] ${dfpGeoJSONUrl._cacheKey} != ${newKey}`);
            dfpGeoJSONUrl._cacheKey = newKey;
            const source = layer.getSource();
            if(source.getSource){
                const newSource = source.getSource();
                newSource.refresh();
                source.refresh();
            } else {
                source.refresh();
            }
        } else {
            log.debug(`[dfpGeoJSONUrl._cacheKey] ${dfpGeoJSONUrl._cacheKey} == ${newKey}`);
        }

        return;
    };

    dfpGeoJSONUrl.params = params;

    return dfpGeoJSONUrl;
}

export function createLayerCacheKey(query: Record<string, any> = {}): string {
    query = omitEmptyPropsRecursive(query);
    const key = Object.keys(query)
        .filter((key) => {
            return !['$limit', '$skip', '$sort', '$modify'].includes(key) &&
            typeof query[key] !== 'undefined';
        })
        .sort()
        .map((k) => `${k}:${JSON.stringify(query[k])}`)
        .join(',');

    return key;
}

export function omitEmptyPropsRecursive(obj: Record<string, any>): Record<string, any> {
    const newObj: Record<string, any> = {};
    Object.entries(obj).forEach(([key, val]) => {
        if(val === null || val === undefined){
            return;
        }
        if(Array.isArray(val)){
            if(!val.length){
                return;
            }
            newObj[key] = val;
        } else if(typeof val === 'object'){
            if(!Object.keys(val).length){
                return;
            }
            newObj[key] = omitEmptyPropsRecursive(val);
        } else {
            newObj[key] = val;
        }
    });
    return newObj;
}
