import { Field, Option } from '@wsb_dev/datafi-shared/lib/types';
import { DefaultActionData } from '@wsb_dev/datafi-shared/lib/types/ActionTypes';
import { bindable } from 'aurelia-framework';
import { Program } from '../../../types/Program';
import { ProgramSurvey } from '../../../types/ProgramSurvey';
import { getGenerateCSV } from './action-schemas/getGenerateCSV';
import { getGenerateKoboURL } from './action-schemas/getGenerateKoboURL';
import { getGeneratePDF } from './action-schemas/getGeneratePDF';
import { getPopulate } from './action-schemas/getPopulate';
import { getQuery } from './action-schemas/getQuery';
import { getSaveFile } from './action-schemas/getSaveFile';
import { IActionParams } from './action-schemas/IActionParams';
import { getEditDFPForm } from './action-schemas/getEditDFPForm';

const schemaGetters: Record<string, (scope: IActionParams) => Field[]> = {
    GenerateCSV: getGenerateCSV,
    SaveFile: getSaveFile,
    GenerateKoboURL: getGenerateKoboURL,
    GeneratePDF: getGeneratePDF,
    Query: getQuery,
    Populate: getPopulate,
    EditDFPForm: getEditDFPForm,
};

export class ProgramSurveyActionEdit {
    @bindable action: DefaultActionData;
    @bindable stepNumber = 1;
    @bindable survey: ProgramSurvey;
    @bindable program: Program;

    actionSchemas: Field[];

    actionScopes: Option[] = [
        { value: 'program-surveys', label: 'Program Surveys List'},
        { value: 'project-surveys', label: 'Project Surveys List'},
        { value: 'program-surveys-table', label: 'Program Surveys Table' },
        { value: 'project-surveys-table', label: 'Project Surveys Table' },
        { value: 'surveys-table', label: 'All Survey Tables'},
    ]

    actionAllowed: Option[] = [
        { value: 1, label: 'Yes' },
        { value: 0, label: 'No' },
    ]

    attached() {
        this.updateActionSchemas(this.action.id);

        if (!this.action.scopes) {
            this.action.scopes = [];
        }
    }

    updateActionSchemas(id: string) {
        this.actionSchemas = schemaGetters[id] ?
            schemaGetters[id]({
                action: this.action,
                survey: this.survey,
                program: this.program,
            }) :
            [];
    }

    handleActionChange(action: string) {
        this.updateActionSchemas(action);
        if (this.action.next) {
            return;
        }
        const nextActions = {
            GenerateCSV: 'SaveFile',
            GeneratePDF: 'SaveFile',
        };

        const nextAction = nextActions[action];
        if (nextAction) {
            this.action.next = {
                id: nextAction,
            };
        }
    }
}
