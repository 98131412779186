import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject, bindable, PLATFORM } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { flattenFields } from '@wsb_dev/datafi-shared/lib/util/surveys/flattenFields';
import { FieldEditDialog } from '../field-edit-dialog/field-edit-dialog';
PLATFORM.moduleName('../field-edit-dialog/field-edit-dialog');
import { ConfirmDialog, ConfirmDialogActivate } from '../../confirm-dialog/confirm-dialog';

@autoinject
export class FieldEditTable {
    @bindable fields: Field[] = [];
    @bindable id: string;
    @bindable flattenedFields: Field[] = [];

    constructor(
        private dialogService: DialogService,
    ) { }

    attached() {
        this.setFlattenedFields();
    }

    fieldsChanged() {
        this.setFlattenedFields();
    }

    setFlattenedFields() {
        this.flattenedFields = flattenFields(this.fields, null, {preserveParent: true}).map((field) => {
            const fieldPath = field.parent?.path ? `${field.parent.path}.${field.name}` : field.path;
            return {
                name: field.name,
                label: field.label,
                type: field.type,
                path: fieldPath,
                parent: fieldPath.split('.').slice(0, -1).join('.'),
                ...field.visibility,
            };
        }).sort((a,b) => a.order - b.order);
    }

    getPointer(field: Field) {
        const fieldPathSegments = field.path.split('.');
        let indexPointer;
        let fieldsArr = this.fields;
        fieldPathSegments.forEach((pathSegment, i) => {
            indexPointer = fieldsArr.findIndex((f) => f.name === pathSegment);
            if (i !== fieldPathSegments.length - 1) fieldsArr = fieldsArr[indexPointer].fields;
        });
        return {indexPointer, fieldsArr};
    }

    // addField() {
    //     const newField = {
    //         label: 'New Field',
    //         path: 'new_field',
    //         name: 'new_field',
    //         type: 'text',
    //     } as Field;
    //     this.fields = [
    //         ...this.fields,
    //         { ...newField, visibility: { order: this.flattenedFields.length + 1 } },
    //     ];
    //     this.flattenedFields = [
    //         ...this.flattenedFields,
    //         { ...newField, order: this.flattenedFields.length + 1 },
    //     ];
    // }

    // openDeleteDialog(field: Field, index: number) {
    //     return this.dialogService.open({
    //         viewModel: ConfirmDialog,
    //         model: {
    //             title: 'Delete Field',
    //             message: `Are you sure you want to delete the field '${field.label}'?`,
    //         } as ConfirmDialogActivate,
    //     }).whenClosed((result) => {
    //         if (result.wasCancelled) return;
    //         this.removeField(field, index);
    //     });
    // }

    // removeField(field: Field, index: number) {
    //     const {indexPointer, fieldsArr} = this.getPointer(field);
    //     this.flattenedFields.splice(index, 1);
    //     fieldsArr.splice(indexPointer, 1);
    // }

    openEditDialog(field: Field, index: number) {
        const {indexPointer, fieldsArr} = this.getPointer(field);

        return this.dialogService.open({
            viewModel: FieldEditDialog,
            model: { field: fieldsArr[indexPointer] },
        }).whenClosed((result) => {
            if (result.wasCancelled) return;
            this.editField(field, index, result.output);
        });
    }

    editField(flatField: Field, flatIndex: number, editedField: Field) {
        const {indexPointer, fieldsArr} = this.getPointer(flatField);

        fieldsArr[indexPointer] = {...editedField};
        const flattened = [...this.flattenedFields];
        flattened[flatIndex] = {
            name: editedField.name,
            label: editedField.label,
            type: editedField.type,
            path: editedField.path,
            parent: flattened[flatIndex].parent,
            ...editedField.visibility,
        };
        this.flattenedFields = flattened;
    }

    setVisibility(flatField: Field, visType: string) {
        const {indexPointer, fieldsArr} = this.getPointer(flatField);
        const field = fieldsArr[indexPointer];
        field.visibility[visType] = flatField[visType];
    }

    // cloneField(field: Field, index: number) {
    //     const {indexPointer, fieldsArr} = this.getPointer(field);

    //     fieldsArr.push({
    //         ...fieldsArr[indexPointer],
    //         path: field.path + '_copy',
    //         name: field.name + '_copy',
    //     });

    //     this.flattenedFields.splice(index + 1, 0, {
    //         ...field,
    //         path: field.path + '_copy',
    //         name: field.name + '_copy',
    //     });

    //     this.setFieldOrder(this.flattenedFields);
    // }

    getDragPreview(field: Field) {
        const el = document.createElement('mdc-data-table');
        el.className = 'mdc-data-table';
        el.innerHTML = `
        <table class='mdc-data-table__table au-target'>
            <tbody class='mdc-data-table__content au-target'>
                <tr class='mdc-data-table__row au-target'>
                    <td class='mdc-data-table__cell'>
                        <button class="mdc-icon-button material-icons reorder-handler">
                            <div class="mdc-button__icon">drag_indicator</div>
                        </button>
                    </td>
                    <td class='mdc-data-table__cell'>${field.label}</td>
                    <td class='mdc-data-table__cell'>${field.path}</td>
                    <td class='mdc-data-table__cell'>${field.type}</td>
                </tr>
            </tbody>
        </table>
       `;
        el.style.width = '50%';
        el.style.overflow = 'hidden';
        return el;
    }

    setFieldOrder(newOrder) {
        newOrder.forEach((f, i) => {
            f.order = i + 1;
        });
        this.flattenedFields = newOrder;
        this.applyFieldOrders(this.fields, '', '');
    }

    applyFieldOrders(fields: Field[], parent: string, child: string) {
        const flattenedFieldsMap = {};
        this.flattenedFields.forEach((f) => {
            flattenedFieldsMap[f.path] = f;
        });

        fields.forEach((field) => {
            child = field.name;
            if (field.type === 'object' || field.type === 'array') {
                delete field.path;
                const newParent = parent.concat(field.name + '.');
                this.applyFieldOrders(field.fields, newParent, child);
            } else if ('name' in field) {
                const flattenedRef = flattenedFieldsMap[parent.concat(child)] ||
                    (parent.includes('metadata') && flattenedFieldsMap[child]);
                if (flattenedRef) {
                    field.visibility['order'] = flattenedRef.order;
                }
            }
        });
    }
}
