import { bindable, autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Field } from '@wsb_dev/datafi-shared/lib/types';

@autoinject
export class FieldEditDialog {
    @bindable field: Field;

    constructor(
        private dialogController: DialogController,
    ) { }

    activate(model) {
        this.field = model.field;
    }

    submit() {
        return this.dialogController.ok(this.field);
    }
}
